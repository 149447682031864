<template>
  <div>
    <!-- 차트 -->
    <c-card title="LBL0001490" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-sm-12 col-md-8 col-lg-8">
          <apexchart 
            ref="chart1" 
            height="250" 
            type="bar"
            :width="chart1.chartWidth"
            :options="chart1.chartOptions" 
            :series="chart1.series"></apexchart>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-4">
          <apexchart 
            ref="chart2" 
            height="250" 
            type="bar" 
            :width="chart2.chartWidth"
            :options="chart2.chartOptions" 
            :series="chart2.series"></apexchart>
        </div>
      </template>
    </c-card>
    <c-tab
      class="q-mt-sm"
      type="tabcard"
      align="left"
      :inlineLabel="true"
      :tabItems="tabItems"
      v-model="tab"
      @tabClick="tabClick"
    >
      <template v-slot:default="tab">
        <!-- '[' + tab.label + '] 개선 목록' -->
        <c-table
          ref="table"
          :title="setTableLabel(tab.label)"
          tableId="kras01"
          :columns="grid.columns"
          :data="grid.data"
          @linkClick="linkClick"
        >
          <template v-slot:customArea="{ props }">
            <template>
              <span>
                {{props.row.actionCompleteRequestDate}}
                <q-badge 
                  v-if="props.row.completeDateOver > 0
                    && props.row.ibmStepCd !== 'IS00000001' 
                    && props.row.approvalStatusCd !== 'ASC9999999'" 
                  color="negative" 
                  label="지연" 
                  class="blinking" />
              </span>
            </template>
          </template>
        </c-table>
      </template>
    </c-tab>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import selectConfig from '@/js/selectConfig';
export default {
  name: 'impr-action-rate-detail',
  components: {
    apexchart: VueApexCharts
  },
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        tab: 'task',
        clickInfo: '',
        data: [],
        searchParam: {
          plantCd: null,
          actionCompleteRequestDates: [],
          actionDeptCd: '',
          actionVendorCd: '',
          actionFlag: 'D',
        }
      }),
    },
  },
  data() {
    return {
      chart1: {
        chartOptions: {
          title: {
            text: '개선처리'
          },
          plotOptions: {
            bar: {
              horizontal: true
            }
          },
          xaxis: {
            categories: []
          }
        },
        series: [
          {
            name: "건 수",
            data: []
          }
        ],
        chartWidth: '80%',
      },
      chart2: {
        chartOptions: {
          title: {
            text: '즉시처리'
          },
          plotOptions: {
            bar: {
              horizontal: true
            }
          },
          xaxis: {
            categories: []
          }
        },
        series: [
          {
            name: "건 수",
            data: []
          }
        ],
        chartWidth: '80%',
      },
      curTab: 'IS00000001',
      tab: 'IS00000001',
      tabItems: [],
      tabParam: {
        search: '',
      },
      grid: {
        columns: [],
        data: [],
        allData: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.ibm.improve.status.list.url
      // code setting
      // list setting
      this.setChart();
      this.getList();
    },
    setTableLabel(_s1) {
      return this.$comm.getLangLabel('LBL0001491', {s1: _s1})
    },
    setChart() {
      // '[' + this.popupParam.clickInfo + '] 개선처리'
      this.chart1.chartOptions.title.text = this.$comm.getLangLabel('LBL0001492', {s1: this.popupParam.clickInfo})
      this.$comm.getStepItems('IBM_STEP_CD').then(_result => {
        this.chart1.chartOptions.xaxis.categories = this.$_.map(_result, 'stepperMstNm')
        this.$_.forEach(_result, item => {
          this.tabItems.push({
            name: item.stepperMstCd,
            label: item.stepperMstNm,
            icon: item.iconClass
          })
        })
        // 즉시처리
        this.tabItems.push({ name: 'immTotal', icon: 'task_alt', label: this.$comm.getLangLabel('LBL0001496')})
      });
      // '[' + this.popupParam.clickInfo + '] 즉시처리'
      this.chart2.chartOptions.title.text = this.$comm.getLangLabel('LBL0001493', {s1: this.popupParam.clickInfo})
        // 즉시처리건
      this.chart2.chartOptions.xaxis.categories = [this.$comm.getLangLabel('LBL0001476')]
      this.chart1.series[0].data = [
        this.popupParam.data.requesting,
        this.popupParam.data.receipting,
        this.popupParam.data.actioning,
        this.popupParam.data.confirming,
        this.popupParam.data.complete,
      ]
      this.chart2.series[0].data = [
        this.popupParam.data.immTotal,
        // this.popupParam.data.immComplete,
      ]
      setTimeout(() => {
        this.chart1.chartWidth = '100%';
        this.chart2.chartWidth = '100%';
      }, 200);
      this.$refs['chart1'].refresh();
      this.$refs['chart2'].refresh();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.popupParam.searchParam;
      this.$http.request((_result) => {
        this.grid.allData = _result.data;
        this.tabClick({ name: this.tab });
      },);
    },
    tabClick(tab) {
      this.curTab = tab.name;
      if (tab.name !== 'immTotal') {
        // 개선처리건일 경우
        this.grid.columns = [
          {
            name: 'plantName',
            field: 'plantName',
            // 사업장
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'ibmTaskTypeName',
            field: 'ibmTaskTypeName',
            // 업무
            label: 'LBLWORK',
            align: 'center',
            style: 'width:120px',
            sortable: true
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            // 제목
            label: 'LBLTITLE',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'improveRequestContents',
            field: 'improveRequestContents',
            // 요청정보
            label: 'LBL0001420',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'actionCompleteRequestDate',
            field: 'actionCompleteRequestDate',
            // 조치완료 요청일
            label: 'LBL0001419',
            align: 'center',
            style: 'width:100px',
            type: 'custom',
            sortable: true,
          },
          {
            name: 'actionName',
            field: 'actionName',
            label: '조치부서',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'actionUserNames',
            field: 'actionUserNames',
            // 조치자
            label: 'LBL0001416',
            align: 'left',
            style: 'width:100px',
            sortable: true,
          },
        ];
        // 개선완료인 경우
        // if (tab.name === 'IS00000020') {
        //   this.grid.columns = this.$_.concat(this.grid.columns, [
        //     {
        //       name: 'suppleFlagName',
        //       field: 'suppleFlagName',
        //       // 적합/보완
        //       label: 'LBL0001468',
        //       align: 'center',
        //       style: 'width:80px',
        //       sortable: true
        //     },
        //     {
        //       name: 'ibmSuppleStepName',
        //       field: 'ibmSuppleStepName',
        //       // 보완진행단계
        //       label: 'LBL0001464',
        //       align: 'center',
        //       style: 'width:100px',
        //       sortable: true
        //     },
        //     {
        //       name: 'suppleRequest',
        //       field: 'suppleRequest',
        //       // 보완요청 내용
        //       label: 'LBL0001469',
        //       align: 'left',
        //       style: 'width:200px',
        //       sortable: true,
        //     },
        //     {
        //       name: 'suppleScheduleDate',
        //       field: 'suppleScheduleDate',
        //       // 보완완료 요청일
        //       label: 'LBL0001431',
        //       align: 'center',
        //       style: 'width:170px',
        //       sortable: true,
        //     },
        //     {
        //       name: 'suppleCompleteDate',
        //       field: 'suppleCompleteDate',
        //       // 보완완료일
        //       label: 'LBL0001438',
        //       align: 'center',
        //       style: 'width:90px',
        //       sortable: true,
        //     },
        //   ])
        // }
        this.grid.data = this.$_.filter(this.grid.allData, { ibmStepCd: tab.name, ibmClassCd: 'IC00000001' })
      } else {
        // 즉시처리건일 경우
        this.grid.columns = [
          {
            name: 'plantName',
            field: 'plantName',
            // 사업장
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'ibmTaskTypeName',
            field: 'ibmTaskTypeName',
            // 업무
            label: 'LBLWORK',
            align: 'center',
            style: 'width:120px',
            sortable: true
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            // 제목
            label: 'LBLTITLE',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'actionContents',
            field: 'actionContents',
            // 조치내용
            label: 'LBL0001404',
            align: 'left',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'improveRequestContents',
            field: 'improveRequestContents',
            // 요청정보
            label: 'LBL0001420',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'actionCompleteDate',
            field: 'actionCompleteDate',
            // 조치완료일
            label: 'LBL0001426',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ];
        this.grid.data = this.$_.filter(this.grid.allData, { ibmClassCd: 'IC00000005' })
      }
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      if (this.curTab !== 'immTotal') {
        this.popupOptions.title = 'LBL0001415'; // 개선 상세
        this.popupOptions.target = () => import(`${'./imprDetail.vue'}`);
      } else {
        this.popupOptions.title = 'LBL0001495'; // 즉시개선 상세
        this.popupOptions.target = () => import(`${'./imprImmDetail.vue'}`);
      }
      this.popupOptions.param = {
        isSearch: true,
        sopImprovementId: row.sopImprovementId,
        ibmTaskTypeCd: row.ibmTaskTypeCd,
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
